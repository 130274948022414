<template>
	<div id="textbook">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">教材管理</a></el-breadcrumb-item>
			</el-breadcrumb>

      <v-weburl-iframe class="content" url="property/material" />
    </index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
import VWeburlIframe from "@/components/weburl-iframe";
export default {
	components: {
    VWeburlIframe,
		index
	},
};
</script>

<style lang="scss">
</style>
